import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Customers from '../components/Customers';
import Section, { ColumnsSection, SectionColumn } from '../components/Section';
import LinkButton from '../components/LinkButton';
import { MainTitle }  from '../components/Titles';
import MainImageSection from '../components/MainImageSection';
import Landing from '../components/Landing';

import { colors } from '../shared/styles';

const LANDING_SCROLL_OFFSET = 200;


const HomeMainImageSection = styled(MainImageSection)`
  margin-top : 0px;
`;

const HomeColumnsSection = styled(ColumnsSection)`
  margin-top : 12rem;
`

const Column = styled(SectionColumn)`
  text-align : justify;
  font-size : 2rem;
  letter-spacing : 0.1rem;
  line-height : 3rem;
`;

const ReferenceColumn = styled(Column)`
  text-align : center;
  font-style : italic;
`;

const LinkButtonSection = styled(Section)`
  padding-top : 10rem;
  padding-bottom : 10rem;
  text-align : center;
`;

const Highlight = styled.span`
  font-weight : bold;
`;


const IndexPage = () => {
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  
  const [landinPageVisibility, setLandinPageVisibility] = useState(1);
  const [titleVisible, setTitleVisible] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const value = Math.max(0, (LANDING_SCROLL_OFFSET + currPos.y)/LANDING_SCROLL_OFFSET);

      if (landinPageVisibility === value) return

      setLandinPageVisibility(value);

      if (value == 0 && !titleVisible) {
        setTitleVisible(true);
      }
    },
    [landinPageVisibility, titleVisible]
  );

  if(hasMounted && !titleVisible && typeof window !== 'undefined' && window.location.hash === '#home') {
    setTitleVisible(true);
  }
  
  return (
    <React.Fragment>
      <Layout offsetTop={titleVisible ? 0 : LANDING_SCROLL_OFFSET*1.3}>
        <Landing visibilityValue={titleVisible ? 0 : landinPageVisibility}/>
        <SEO title="Home" />
          <MainImageSection id="home" image={require('../images/home_image.jpg')}>
          {
            titleVisible ?
              <MainTitle
                  strings={[
                    "Tell me more est l'agence qui redonne du sens à l'action",
                    "en reconnectant les marques avec leurs clients"
                  ]}
                /> :
                null
          }
        </MainImageSection>
        <HomeColumnsSection>
          <Column>
            Tell Me More est une agence spécialisée en études marketing qui nourrit et accompagne la stratégie des entreprises au travers <Highlight>d’approches immersives et collaboratives.</Highlight>
          </Column>
          <Column>
            Dans un monde gouverné par la data, Tell Me More a pour vocation de <Highlight>reconnecter les marques avec ses clients et de redonner du sens à l’action</Highlight> au travers de <Highlight>méthodologies agiles, centrées sur le dialogue, et 100% « orientées clients ».</Highlight>
          </Column>
          <Column>
            <Highlight>Agence spécialisée sur les problématiques d’innovation et d’expérience client</Highlight>, Tell Me More vous accompagne dans la connaissance fine de votre marché et de vos clients pour travailler votre stratégie de marque, votre positionnement et identifier de nouvelles opportunités de développement.
          </Column>
        </HomeColumnsSection>
        <LinkButtonSection>
          <LinkButton to="/offre">Nos offres</LinkButton>
        </LinkButtonSection>
        <Customers/>
        <HomeColumnsSection>
          <ReferenceColumn>
            « Nous avons travaillé avec Tell Me More au sujet de la pratique du ski nordique. Le timing et le budget étaient très serrés mais Delphine a su nous délivrer les insights attendus avec ces contraintes importantes. C’est quelqu’un de confiance, qui a un bon réseau de partenaires, … »
          </ReferenceColumn>
          <ReferenceColumn>
            « J’ai eu l’occasion de collaborer avec Tell Me more a plusieurs reprises sur différents sujets pour le secteur la dermo-cosmétique à l’international (France, US, Chine, Mexique, Espagne..)
    Cela a été un réel plaisir de travailler avec Delphine sur ces différents projets…. »
          </ReferenceColumn>
        </HomeColumnsSection>
        <LinkButtonSection>
          <LinkButton to="/clients">En savoir plus</LinkButton>
        </LinkButtonSection>
      </Layout>
    </React.Fragment>
  );
}

export default IndexPage
