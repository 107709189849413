import React, { useState, useEffect } from "react"
import styled from "styled-components";
import Typewriter from 'typewriter-effect';

import { colors } from '../shared/styles';


const Container = styled.div`
  position : fixed;
  top : 0;
  left : 0;
  width : 100%;
  height : 100%;
  background-color : ${colors.yellow};
  z-index : 1;
  background-image : url('${require('../images/landing_phone.jpg')}');
  background-position : left center;
  background-size : contain;
  background-repeat : no-repeat;
  display : flex;
  flex-direction : row;
  justify-content : flex-end;
  align-items : center;

  @media screen and (max-width : 425px) {
    background-image : url('${require('../images/landing_phone_mobile.jpg')}');
    background-position : center top;
    background-size : contain;
    background-repeat : no-repeat;
    background-size : 30rem;
  }
`;

const Column = styled.div`
  width : 50%;
  height : 100%;
  display : flex;
  flex-direction : column;
  justify-content : center;
  align-items : center;
  font-size : 3rem;
  font-family : monospace;
  letter-spacing : 0.2rem;
  line-height : 4rem;
  text-align : center;
  padding : 3rem;

  @media screen and (max-width : 425px) {
    width : 100%;
  }

`

const Logo = styled.img`
  padding-top : 50px;
  width : 45rem;
  opacity : 0;
  transition : opacity 0.5s ease;

  &.visible {
    opacity : 1;
  }
`;

const Arrow = styled.img`
  position : absolute;
  bottom : 4rem;
  width : 6rem;
  opacity : 0;
  transition : opacity 0.5s ease;

  &.visible {
    opacity : 1;
  }

  @keyframes move-arrow {
    0% {
      transform : translateY(0rem);
    }

    100% {
      transform : translateY(-2rem);
    }
  }

  animation : 0.8s ease infinite alternate move-arrow;
`;

const Intro = styled.span`
opacity : 0;
transition : opacity 0.5s ease;

&.visible {
  opacity : 1;
}
`;

const Landing = ({ visibilityValue }) => {
  const [introVisible, setIntroVisible] = useState(false);
  const [logoVisible, setLogoVisible] = useState(false);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    setTimeout(() => setIntroVisible(true), 200);
    setTimeout(() => setLogoVisible(true), 400);
  }, [introVisible, logoVisible]);

  if(!hidden && visibilityValue < 0.01) {
    setHidden(true);
  }

  return (
    <Container
      style={{
        'transform' : `translateY(-${(1-visibilityValue)*100}%)`,
        'opacity'   : visibilityValue > 0.5 ? 1 : visibilityValue/0.5,
        'display'   : hidden ? 'none' : 'flex'
      }}
      >
      <Column>
        <Intro
          className={introVisible ? 'visible' : ''}
          >
          Tell Me More accompagne les marques dans leurs stratégies d'innovation et d'expérience client
        </Intro>
        <Logo
          src={require('../images/logo_TellMeMore_black.png')}
          className={logoVisible ? 'visible' : ''}
          />
        <a href="#home">
          <Arrow
            src={require('../images/down.png')}
            className={logoVisible ? 'visible' : ''}
            />
        </a>
      </Column>
    </Container>
  )
};

export default Landing;

// <Typewriter
//   onInit={(typewriter) => {
//     typewriter.typeString(
//       "Tell Me More accompagne les marques dans leurs stratégies d'innovation et d'expérience client"
//     )
//     .pauseFor(300)
//     .callFunction(
//       () => setLogoVisible(true)
//     )
//     .start();
//   }}
//   options={{
//     delay : 50,
//     loop : false
//   }}
//   />
//
